<template>
  <TransitionRoot appear :show="visible" as="template">
    <Dialog as="div" class="relative z-30 w-full">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full justify-center text-center sm:items-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="min-h-full w-full transform bg-white text-left align-middle shadow-xl transition-all sm:max-w-2xl md:rounded-md"
            >
              <div class="my-2 px-4 sm:p-5">
                <div class="flex flex-col items-center gap-2 sm:pb-4">
                  <h1 class="text-xl text-center font-semibold leading-7 mt-3">
                    ¡Nos faltaron algunos datos!
                  </h1>
                  <h3 class="text-base text-center font-light leading-5 mb-3">
                    Ingresa los siguientes datos para acceder a la experiencia
                    FREST
                  </h3>
                </div>
                <Form
                  @submit="onSubmit"
                  @invalid-submit="onInvalidSubmit"
                  :validation-schema="completarRegistroSchema"
                  class="w-full flex flex-col items-center gap-2"
                >
                  <div class="relative flex w-full">
                    <div
                      class="absolute left-px top-px inline-flex items-center rounded-l-md border-y border-gray-100 bg-gray-100 px-3 py-2 text-sm text-gray-900"
                    >
                      +569
                    </div>
                    <Field
                      class="h-10 w-full rounded-md border-gray-200 pl-16 focus:border-green-500 focus:ring-green-500 focus-visible:border-green-500 focus-visible:ring-green-500"
                      :class="{
                        'border-red-500': errorCelular,
                        'border-green-500': errorCelular === false,
                      }"
                      v-model="datosRegistro.celular"
                      placeholder="Ingresa tu número de teléfono"
                      type="tel"
                      name="celular"
                      @input="onInputCelular"
                      @keyup="
                        datosRegistro.celular = datosRegistro.celular.replace(
                          /[^0-9 ]/g,
                          '',
                        )
                      "
                      @blur="onBlurCelular"
                    />
                    <component
                      :is="
                        errorCelular
                          ? XCircleIcon
                          : errorCelular === false
                          ? CheckCircleIcon
                          : null
                      "
                      class="absolute right-3 top-3 h-5 w-5"
                      :class="{
                        'text-red-500': errorCelular,
                        'text-green-500': errorCelular === false,
                      }"
                    />
                  </div>
                  <div
                    class="w-full"
                    :class="{
                      'text-red-500': errorCelular,
                      'text-green-500': errorCelular === false,
                    }"
                  >
                    <Select
                      v-if="$opcionesActivacion"
                      name="activacionMarca"
                      v-model="datosRegistro.activacionMarca"
                      placeholder="¿Dónde nos conociste?"
                      class="h-10 w-full rounded-md border-gray-200 pl-16"
                      :opciones="listaActivacion ?? []"
                      label=""
                    />
                  </div>
                  <p
                    class="h-5 w-full text-left text-sm font-normal leading-5"
                    :class="{
                      'text-red-500': error,
                      'text-white': !error,
                    }"
                  >
                    {{ error }}
                  </p>
                  <Button class="w-full" type="secondary">Guardar Datos</Button>
                  <div
                    class="mt-4 flex w-full items-center text-sm font-medium"
                  >
                    <Field
                      id="politicasCondiciones"
                      aria-describedby="politicasCondiciones"
                      name="politicasCondiciones"
                      type="checkbox"
                      :value="true"
                      class="h-4 w-4 rounded border-gray-300 text-green-500 focus:ring-transparent"
                      :class="{
                        'border-red-500': errorPoliticasCondiciones,
                      }"
                      v-model="datosRegistro.politicasCondiciones"
                    />
                    <label for="politicasCondiciones" class="ml-2"
                      >He leído y acepto los
                      <a
                        class="text-green-500 underline underline-offset-4"
                        href="/condiciones"
                        target="_blank"
                        >Términos y condiciones</a
                      >
                      de uso</label
                    >
                  </div>
                </Form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
  import { useStore } from "@nanostores/vue";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
  } from "@headlessui/vue";
  import Button from "@ui/Button.vue";
  import Select from "@components/ui/form/Select.vue";
  import { Form, Field } from "vee-validate";
  import { ref, computed, onMounted } from "@vue/runtime-core";
  import { completarRegistroSchema } from "@lib/formSchemas";
  import {
    configOpcionesActivacionMarca,
    configRadiosActivacionMarca,
  } from "@stores/app";
  import { XCircleIcon, CheckCircleIcon } from "@heroicons/vue/20/solid";
  import type { User } from "@lib/interfaces";
  import { guardarDatosCompletarRegistro } from "@api/client/users";

  interface Props {
    user: User | null;
  }

  type InvalidSubmit = {
    errors: {
      email?: string;
      celular?: string;
      politicasCondiciones?: string;
      activacionMarca?: string;
    };
  };

  const visible = ref(false);
  const props = defineProps<Props>();
  const datosRegistro = ref({
    celular: "",
    activacionMarca: "",
    politicasCondiciones: false,
  });
  const error = ref("");
  const errorCelular = ref<boolean | undefined>(undefined);
  const errorPoliticasCondiciones = ref<boolean | undefined>(undefined);
  const $opcionesActivacion = useStore(configOpcionesActivacionMarca);
  const $radiosActivacion = useStore(configRadiosActivacionMarca);
  onMounted(() => {
    if (props.user && (!props.user.celular || !props.user.activacion_marca)) {
      visible.value = true;
      datosRegistro.value.celular = props.user.celular ?? "";
      onInputCelular();
      datosRegistro.value.activacionMarca = props.user.activacion_marca ?? "";
      datosRegistro.value.politicasCondiciones = props.user.acepto_terminos;
    }
  });

  const onSubmit = () => {
    error.value = "";
    if (!completarRegistroSchema.isValidSync(datosRegistro.value)) return;
    errorCelular.value = false;
    guardarDatos();
  };

  const onInvalidSubmit = ({ errors }: InvalidSubmit) => {
    if (errors.politicasCondiciones) {
      error.value = errors.politicasCondiciones;
      errorPoliticasCondiciones.value = true;
    } else {
      errorPoliticasCondiciones.value = false;
    }
    if (errors.activacionMarca) {
      error.value = errors.activacionMarca;
    }
    if (errors.celular) {
      error.value = errors.celular;
      errorCelular.value = true;
    } else {
      errorCelular.value = false;
    }
  };

  const onInputCelular = () => {
    if (datosRegistro.value.celular.length >= 4) {
      limpiarCelular();
    }
  };
  const onBlurCelular = () => {
    limpiarCelular();
    const CELULAR_REGEX = /^[0-9]{4} [0-9]{4}$/;
    errorCelular.value = !CELULAR_REGEX.test(datosRegistro.value.celular);
  };

  const limpiarCelular = () => {
    datosRegistro.value.celular = datosRegistro.value.celular
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d{1,4})/, "$1 $2");
  };

  const closeModal = () => {
    visible.value = false;
  };

  const guardarDatos = async () => {
    let response = await guardarDatosCompletarRegistro(
      datosRegistro.value.celular.replace(/ /g, ""),
      datosRegistro.value.activacionMarca,
      datosRegistro.value.politicasCondiciones,
    );
    if (response.estado !== "ok") return alert("Error al guardar los datos");
    closeModal();
  };

  const listaRadios = computed(() => {
    if (!$radiosActivacion.value.length) return;
    return $radiosActivacion.value.map((radio) => `Lo escuché en la ${radio}`);
  });

  const listaActivacion = computed(() => {
    if (!$opcionesActivacion.value.length) return;
    if (listaRadios.value) {
      return [...$opcionesActivacion.value, ...listaRadios.value, "Otro"];
    }
    return [...$opcionesActivacion.value, "Otro"];
  });
</script>
